import { HewSync } from "./HewSync";
import { HewSyncType } from "./HewSyncType";

export class DynamoType extends HewSyncType {
	@HewSync.Field({
		type: "Timestamp"
	})
	public readonly createdAt: HewSync.Timestamp;

	@HewSync.Field({
		type: "Timestamp"
	})
	public readonly updatedAt: HewSync.Timestamp;
}
