export function normalizeCode(input: TemplateStringsArray, ...args: any[]) {
	let last = input[input.length - 1]!;
	let lastTabCount = 0;
	for (let i = last.length - 1; i >= 0; i--) {
		if (last[i] === "\t") {
			lastTabCount++;
		} else {
			break;
		}
	}

	let text = "";

	for (let i = 0; i < args.length + 1; i++) {
		let part = input[i]!;
		let start = i === 0 ? input[i]!.indexOf("\n") + 1 : 0;

		let j = start;
		let length = i < args.length ? part.length : part.length - lastTabCount - 1;

		let line = false;
		while (j < length) {
			// skip tabs
			if (part[j] === "\t") {
				let k;
				for (k = 0; k < lastTabCount; k++) {
					if (part[j + k] === "\n") {
						line = true;
						break;
					} else if (part[j + k] !== "\t") {
						console.log(part[j + k]);
						throw new Error("Invalid code C");
					}
				}
				j += k;
			}

			let end = part.indexOf("\n", j);
			if (end >= length) {
				end = -1;
			}

			if (end === -1) {
				line = true;
				break;
			}
			text += part.substring(j, end);
			text += "\n";

			j = end;
			j++;
		}

		if (line) {
			// if (line) {
			let tabs = 0;
			while (j < length) {
				if (part[j] === "\t") {
					tabs++;
					j++;
				} else {
					break;
				}
			}

			if (j < length) {
				text += "\t".repeat(tabs) + part.substring(j, length);
				if (i < args.length) {
					text += `${args[i] as string}`;
				}
			} else {
				if (i < args.length) {
					text += `${args[i] as string}`
						.split("\n")
						.map((x: string) => "\t".repeat(tabs) + x)
						.join("\n");
				}
			}
		} else {
			text += part.substring(j, length);
			if (i < args.length) {
				text += args[i];
			}
		}
	}

	return text;
}
