import { Base64, BasicEvent } from "@h4x/common";
import { normalizeCode } from "./HewSyncUtils";
import { HewSync, type HewSyncTypeConfig } from "./HewSync";

/* async function b() {
	let ws = new WebSocket(
		`wss://${hostWS}/graphql?header=${encodeURIComponent(
			Base64.default.encodeString(
				JSON.stringify({
					Authorization: $appSyncKey,
					host: host
				}),
				true
			)
		)}&payload=e30=`,
		"graphql-ws"
	);

	ws.addEventListener("open", function (event) {
	});

	ws.addEventListener("message", function (event) {
		console.log("Message from server ", event.data);
		log += "<< " + JSON.stringify(JSON.parse(event.data), null, "\t") + "\n\n";
	});
}*/

export class HewSyncSocket {
	private auth?: string;
	private host?: string;
	private hostWS?: string;

	private disabled = false;

	constructor() {}

	public disable() {
		this.disabled = true;
	}

	private ws: WebSocket | undefined;

	public connect(host: string, hostWS: string, auth?: string) {
		if (auth && this.auth !== auth) {
			this.auth = auth;
			this.ws?.close();
			this.ws = undefined;
		}
		this.host = host;
		this.hostWS = hostWS;

		if (this.ws) {
			return;
		}
		if (this.auth === undefined) {
			throw new Error("Not authenticated");
		}
		this.ws = new WebSocket(
			`wss://${this.hostWS}/graphql?header=${encodeURIComponent(
				Base64.default.encodeString(
					JSON.stringify({
						Authorization: this.auth,
						host: this.host
					}),
					true
				)
			)}&payload=e30=`,
			"graphql-ws"
		);

		this.authorized = new Promise((resolve) => {
			this.authorizedResolve = resolve;
		});

		this.ws.addEventListener("open", (event) => {
			this.onOpen();
		});

		this.ws.addEventListener("message", (event) => {
			try {
				this.onMessage(JSON.parse(event.data as string) as { type: string; [id: string]: any });
			} catch (e) {
				console.error(e);
			}
		});
	}

	public proxy(sender: (data: string) => void, receiver: BasicEvent<(data: string) => void>) {
		this.host = "proxy";
		this.hostWS = "proxy";

		this.ws = {
			send: (data: string) => {
				sender(data);
			},
			close: () => {}
		} as any as WebSocket;

		receiver.addCallback((data) => {
			this.onMessage(JSON.parse(data) as { type: string; [id: string]: any });
		});

		this.authorized = Promise.resolve(true);
	}

	private onOpen() {
		console.debug("[HewSyncSocket] Successfully connected");
		this.send({ type: "connection_init", payload: {} });
		// {"type":"connection_ack","payload":{"connectionTimeoutMs":300000}}
	}

	private authorized: Promise<true> | true | undefined = undefined;
	private authorizedResolve: ((result: true) => void) | undefined = undefined;
	private onMessage(data: {
		type: string;
		id?: string;
		payload?: { errors: any[]; data: { output: any; output_batch: any } };
		[id: string]: any;
	}) {
		if (data.type === "connection_ack") {
			this.authorizedResolve?.(true);
			this.authorized = true;
		} else if (data.type === "data") {
			let id = data.id;
			if (data.payload.errors) {
				console.error("[HewSyncSocket] Error", data.payload.errors);
			} else {
				let subscription = this.subscriptions.get(id!);
				console.debug("[HewSyncSocket] Received data:", data.payload!.data, subscription);
				if (subscription) {
					if (data.payload!.data.output_batch) {
						if (data.payload!.data.output_batch.length === 2) {
							let removed = data.payload!.data.output_batch.find((item: any) => item.removed === true);
							let added = data.payload!.data.output_batch.find((item: any) => item.removed === false);

							if (removed && added) {
								subscription.callbackReplace(removed, added);
							}
						}
						for (let item of data.payload!.data.output_batch) {
							subscription.callback(item);
						}
					} else if (data.payload!.data.output) {
						subscription.callback(data.payload!.data.output);
					} else {
						console.error("[HewSyncSocket] Unknown data", data);
					}
				}
			}
		} else if (data.type === "error") {
			console.error("[HewSyncSocket] Error", ...data.payload.errors);
		}
	}

	private subscriptions = new Map<
		string,
		{
			callback: (data: any) => void;
			callbackReplace: (removed: any, added: any) => void;
		}
	>();
	public async subscribe<T>(
		config: HewSyncTypeConfig,
		callback: (data: T) => void,
		callbackReplace: (removed: T, added: T) => void
	) {
		await HewSync.authPromise;
		if (this.disabled) {
			return;
		}
		if (this.authorized === undefined) {
			throw new Error("Not connected");
		} else if (this.authorized !== true) {
			await this.authorized;
		}

		// AppSyncTypeConfig
		if (config.type === undefined) {
			throw new Error("Can't subscribe to an abstract type");
		}
		let name = config.type.scope + "_" + config.type.name;

		let query = normalizeCode`
		subscription {
			output: ${name} {
				${HewSync.prepareFields(config.fieldsList).join("\n")}
			}
		}
		`;
		let queryBatch = normalizeCode`
		subscription {
			output_batch: ${name}_batch {
				${HewSync.prepareFields(config.fieldsList).join("\n")}
			}
		}
		`;

		let id = crypto.randomUUID();
		let idBatch = crypto.randomUUID();
		this.subscriptions.set(id, { callback, callbackReplace });
		this.subscriptions.set(idBatch, { callback, callbackReplace });
		this.send({
			id: id,
			type: "start",
			payload: {
				data: JSON.stringify({
					query: query,
					variables: {
						// id: "d4780448-d051-70ee-2cc7-9e6eaba78fa1"
					}
				}),
				extensions: { authorization: { Authorization: this.auth, host: this.host } }
			}
		});
		this.send({
			id: idBatch,
			type: "start",
			payload: {
				data: JSON.stringify({
					query: queryBatch,
					variables: {
						// id: "d4780448-d051-70ee-2cc7-9e6eaba78fa1"
					}
				}),
				extensions: { authorization: { Authorization: this.auth, host: this.host } }
			}
		});
	}

	private send(data: any) {
		if (!this.ws) {
			throw new Error("Not connected");
		}

		this.ws.send(JSON.stringify(data));
	}

	public get isConnected() {
		return this.ws !== undefined && this.ws.readyState !== WebSocket.CLOSED;
	}
}
